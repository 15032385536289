header.site-header {
    padding: 0 15px;
    position: relative;
    z-index: 4;
    background: url(../img/header-background.jpg);
    background-position: center;
    background-repeat: no-repeat;
    height: 147px;
    filter: drop-shadow(8px 8px 8px #000);


    @include breakpoint(sm) {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 40;
        width: 100vw;
        box-shadow: 0 2px 3px -3px rgba(0, 0, 0, 0.3);
    }

    .container {
        height: 100%;
    }

    ul {
        padding-left: 0;
        margin: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;

        li {
            padding: 0 5px;
            display: block;
        }
    }

    .header-primary-nav {

        padding-left: 0;
        margin: 0;
        display: flex;
        justify-content: space-around;
        align-items: center;
        max-width: 430px;
        flex-wrap: wrap;

        li {
            padding: 4px 5px;
            display: block;
        }

        .current_page_item,
        .current-page-ancestor {
            a {
                text-decoration: underline;
            }
        }


        @include breakpoint(lg) {
            max-width: 350px;
        }

        @include breakpoint(sm) {
            max-width: 100%;
        }
    }


    .header-row-1 {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-evenly;
        align-items: center;
        width: 100%;
        height: 100%;

        @include breakpoint(sm) {
            flex-direction: column;
        }
    }

    .header-community {
        width: 30%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 100%;

        @include breakpoint(sm) {
            width: 100%;
            height: initial;
        }

        .leftSocial {
            @include breakpoint(sm) {
                display: none;
            }
        }

        .diamond {
            background: 0 0;
            border: 2px solid $gold;
            border-radius: 4px;
            height: 32px;
            width: 32px;
            transform: rotate(45deg);
            margin-left: 20px;
            padding: 0 5px;

            @include breakpoint(lg) {
                margin-left: 10px;
            }

            a {
                color: $gold;
                display: table-cell;
                height: 40px;
                width: 40px;
                transform: rotate(-45deg);
                vertical-align: middle;
                display: block;
                margin-left: -3px;
                margin-top: 3px;

            }

            svg {
                width: 15px;
                height: 15px;
                fill: #f9da92;

                &:hover {
                    fill: $red;
                }
            }
        }

        ul {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: flex-start;
            line-height: 1;

            @include breakpoint(sm) {
                justify-content: space-between;
            }

            li {
                margin-left: 8px;

                &:first-child {
                    margin-left: 0;
                }

                a {
                    color: $gold;
                    font-size: 2.0rem;

                }
            }
        }

        .has-icon {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            &:hover,
            &:focus {
                .svg-icon {
                    fill: $gold;
                }
            }
        }

        .svg-icon {
            width: 20px;
            height: 15px;
            fill: $gold;
        }
    }

    .header-main-navigation {
        display: flex;
        justify-content: center;

        @include breakpoint(sm) {
            flex-wrap: wrap;
        }
    }

    .header-main-nav {
        width: 30%;
        // height: 100%;
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;

        @include breakpoint(sm) {
            width: 100%;
        }

        .menu {
            justify-content: flex-end;

            @include breakpoint(sm) {
                display: none;
            }

            li {
                margin-right: 16px;
            }
        }

        svg {
            width: 18px;
            height: 18px;
            fill: $gold;
        }

    }

    .header-row-2 {
        margin-bottom: 15px;

        @include breakpoint(sm) {
            margin-bottom: 8px;
        }
    }

    .logo {
        width: 40%;
        display: flex;
        justify-content: center;
        text-decoration: none;
        align-items: center;

        @include breakpoint(sm) {
            width: 100%;
            justify-content: flex-start;
        }

        img {
            position: relative;
            top: 20px;
            right: 20px;

            @include breakpoint(sm) {
                display: none;
            }
        }

        h1 {
            font-family: $accentFont;
            font-size: 6.0rem;
            font-weight: normal;
            text-shadow: 6px 6px $black;
            letter-spacing: 1px;

            @include breakpoint(premedium) {
                font-size: 3.5rem;
            }

            @include breakpoint(sm) {
                font-size: 3.2rem;
                margin: 0;
                padding: 0;
            }

        }

    }

    #membershipPlaceHolder {
        // margin-bottom: 28px;

        @include breakpoint(sm) {
            margin-bottom: 2px;
        }

        svg {
            margin-right: 3px;
        }
    }



    .header-main-navigation {
        a {
            margin: 10px;
            font-size: 2.4rem;
            display: block;
            color: $gold;
            // font-family: $accentFont;
            // text-shadow: 3px 3px 3px #000000;
            text-decoration: none;

            @include breakpoint(lg) {
                font-size: 2.0rem;
            }


            &:hover {
                text-decoration: underline;
            }

            &:focus {
                outline-offset: 5px;
            }

        }

        .current-menu-item a,
        .current-page-ancestor a {
            text-decoration-color: inherit;
            text-decoration-thickness: 2px;
        }
    }


    @include breakpoint(sm) {
        .header-mobile-wrapper {
            //ATTN: PIXEL AMOUNT NEEDS TO BE EDITED TO NATURAL HEIGHT OF FIXED HEADER AT THIS BREAKPOINT
            height: calc(100vh - 119px);
            overflow-y: scroll;
            position: fixed;
            left: 0;
            right: 0;
            display: none;
            padding: 60px 0;
            background: #3c130c;
            margin-top: 18px;
        }

        .header-mobile-menu-container {
            padding-top: 0px;
            padding-bottom: 0px;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

    @include breakpoint(xs) {
        .header-mobile-wrapper {
            //ATTN: PIXEL AMOUNT NEEDS TO BE EDITED TO NATURAL HEIGHT OF FIXED HEADER AT THIS BREAKPOINT
            height: calc(100vh - 85px);
        }
    }

    //START OF HAMBURGER NAVIGATION
    .hamburger {
        position: absolute;
        top: 57%;
        transform: translateY(-50%);
        right: 30px;
        z-index: 15;
        display: none;
        padding: 5px 2px 0 2px;
        cursor: pointer;
        transition-property: opacity, filter;
        transition-duration: 0.15s;
        transition-timing-function: linear;
        font: inherit;
        color: inherit;
        text-transform: none;
        background-color: transparent;
        border: 0;
        margin: 0;
        overflow: visible;

        &:hover {
            color: $gold;
        }

        &.is-active:hover {
            color: $gold;
        }

        &.is-active {

            .hamburger-inner,
            .hamburger-inner::before,
            .hamburger-inner::after {
                background-color: $gold;
            }
        }

        @include breakpoint(sm) {
            display: inline-block;
        }
    }

    .hamburger-box {
        width: 40px;
        height: 24px;
        display: inline-block;
        position: relative;
    }

    .hamburger-inner {
        display: block;
        top: 50%;
        margin-top: -2px;
    }

    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
        width: 40px;
        height: 4px;
        background-color: $gold;
        border-radius: 0px;
        position: absolute;
        transition-property: transform;
        transition-duration: 0.15s;
        transition-timing-function: ease;
    }

    .hamburger:hover {

        .hamburger-inner,
        .hamburger-inner::before,
        .hamburger-inner::after {
            background-color: $gold;
        }
    }

    .hamburger-inner::before,
    .hamburger-inner::after {
        content: "";
        display: block;
    }

    .hamburger-inner::before {
        top: -10px;
    }

    .hamburger-inner::after {
        bottom: -10px;
    }

    .hamburger-spring .hamburger-inner {
        top: 2px;
        transition: background-color 0.3s ease;
    }

    .hamburger-spring .hamburger-inner::before {
        top: 10px;
        transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
            transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19),
            background-color 0.3s ease;
    }

    .hamburger-spring .hamburger-inner::after {
        top: 20px;
        transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
            transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19),
            background-color 0.3s ease;
    }

    .hamburger-spring.is-active .hamburger-inner {
        transition-delay: 0.22s;
        background-color: transparent !important;
    }

    .hamburger-spring.is-active .hamburger-inner::before {
        top: 0;
        transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
            transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translate3d(0, 10px, 0) rotate(45deg);
    }

    .hamburger-spring.is-active .hamburger-inner::after {
        top: 0;
        transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
            transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translate3d(0, 10px, 0) rotate(-45deg);
    }

    // END OF HAMBURGER NAVIGATION
    .header-main-navigation {
        .mobileSocial {
            display: none;
        }

        ul {
            @include breakpoint(sm) {
                flex-direction: column;

                li {
                    margin: 8px 0;
                }

                a {
                    font-size: 2.0rem;
                }
            }
        }

        .menu-item-has-children {
            position: relative;
            //ATTN: background triangle: You can open up /images/caret-down-solid.svg in code editor and edit the fill property on the path element to match your text color.
            //
            //Option 2 would be to add &#9660; to this menu item text in dashboard menu editor AND delete the background triangle css block below.
            //
            // START OF BACKGROUND TRIANGLE CSS
            padding-right: 20px;
            background-image: url(/wp-content/themes/markknopfler/images/caret-down-solid.svg);
            background-size: 13px 18px;
            background-position: 90% 0px;
            background-repeat: no-repeat;

            @include breakpoint(sm) {
                background-image: none;
            }

            //END OF BACKGROUND TRIANGLE CSS
        }

        .menu-item-has-children>a {
            cursor: pointer;

            @include breakpoint(sm) {
                display: none;
            }
        }

        .sub-menu {
            display: none;
            position: absolute;
            left: 0px;
            top: 0;
            padding-left: 0;
            padding-top: 40px;
            margin-bottom: 0;
            cursor: pointer;
            width: inherit;
            z-index: 2;

            li {
                padding: 8px;
                border-right: 1px solid rgba(0, 0, 0, 0.1);
                border-left: 1px solid rgba(0, 0, 0, 0.1);
                display: block;
                text-align: left;
                background: $white;

                &:last-child {
                    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                }

                &:first-child,
                &:last-child {
                    padding: 8px;

                    @include breakpoint(sm) {
                        padding: 0 10px;
                        border: none;
                    }
                }

                @include breakpoint(sm) {
                    padding: 0 10px;
                    text-align: center;
                    border: none;
                }
            }

            a {
                display: block;

                @include breakpoint(sm) {
                    margin: 0 0 20px;
                }

                &:first-child,
                &.last-child {
                    margin: 0 5px;

                    @include breakpoint(sm) {
                        margin: 0 0 20px;
                    }
                }
            }

            @include breakpoint(sm) {
                display: block;
                position: static;
                padding-top: 0px;
                margin-top: 0;
                margin-bottom: 0px;
            }
        }
    }

    .menu-item-has-children:focus-within {
        .sub-menu {
            display: block;
        }
    }

    //separated the hover and focus-within selectors out so that hover still works in edge, if together the whole selector gets cancelled out by ie & edge not recognising :focus-within
    .menu-item-has-children:hover {
        .sub-menu {
            display: block;
        }
    }
}

.body-with-open-nav header.site-header .header-mobile-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include breakpoint(xs) {
        justify-content: flex-start;
    }
}

.skip-links-container {
    position: absolute;
    top: 15px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;

    @include breakpoint(xs) {
        top: 85px;
        left: 15px;
        transform: none;
    }
}

.skip-links {
    display: inline-block;
    width: 1px;
    height: 1px;
    overflow: hidden;
    font-size: 1.6rem;

    &:focus {
        width: auto;
        height: auto;
        padding: 5px;
        color: $white;
        background: $black;
    }
}