/*
====================================
Custom Font Defaults
====================================
*/

@font-face {
    font-family: 'Helvetica';
    src:url('/ElvisCostelloRebuild/fonts/Helvetica-Bold.woff') format('woff'),
        url('/ElvisCostelloRebuild/fonts/Helvetica-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica';
    src: url('/ElvisCostelloRebuild/fonts/HelveticaLight.woff') format('woff'),
        url('/ElvisCostelloRebuild/fonts/HelveticaLight.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: helveticaultra_compressed;
    src: url('/ElvisCostelloRebuild/fonts/Helvetica-UltraCompressed.woff') format('woff'),
        url('/ElvisCostelloRebuild/fonts/Helvetica-UltraCompressed.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}