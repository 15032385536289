.description {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    width: 100%;
    padding: 0 0 60px;
}

.description-left {
    width: 40%;
    padding-right: 60px;
}

.description-right {
    width: 60%;
}

.description h3 {
    margin-top: 0;
}

.intro-top {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
}

@media(max-width: 767px) {
    .description {
        display: block;
    }

    .description-left {
        width: 100%;
        padding-right: 0;
    }

    .description-right {
        width: 100%;
    }

    .description h3 {
        margin-top: 30px;
    }

    .intro-top {
        display: block;
    }
}

.tour-login {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
}

@media(max-width: 767px) {
    .tour-login {
        display: block;
    }
}

.tour-login p {
    width: 55%;
    padding-right: 15px;
}

@media(max-width: 767px) {
    .tour-login p {
        display: block;
        width: 100%;
        padding-right: 0;
    }
}

.tour-login .memberButtons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    margin-top: 18px;
    width: 37%;
}

@media(max-width: 767px) {
    .tour-login .memberButtons {
        display: block;
        width: 100%;
        margin-top: 40px;
        margin-bottom: 40px;
    }
}

.tour-login .memberButtons a {
    padding: 10px 25px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    border: 1px solid $secondary;
    margin-right: 20px;
    text-decoration: none;
    font-size: 2rem;
}

.tour-login .memberButtons a:hover {
    color: $secondary;
    background: $secondary;
}

.tour-login .memberButtons a:nth-child(2) {
    margin-right: 0;
}

@media(max-width: 767px) {
    .tour-login .memberButtons a {
        margin-right: 10px;
    }
}

table.shows-list {
    border-collapse: collapse;
    width: 100%;
    margin-top: 70px;
}

@media(max-width: 992px) {
    table.shows-list {
        font-size: 1.6rem;
    }
}

table.shows-list td {
    border-bottom: 1px solid $secondary;
}

.centered {
    text-align: center;
}

table.shows-list th {
    text-align: left;
}

table.shows-list td,
table.shows-list th {
    vertical-align: middle;
    border-bottom: 1px solid $gold;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 1px;
    padding: 20px 20px 20px 0;
}

table.shows-list td:last-child {
    padding-right: 0;
}

table.shows-list .details-note {
    padding-top: 10px;
    display: inline-block;
}

table.shows-list a {
    font-weight: 400;
    width: 100%;
}

table.shows-list .button {
    line-height: 1.5;
    font-size: 1.5rem;
    padding: 10px 15px;
}

.header-date {
    width: 28%;
    min-width: 257px;
}

@media(max-width: 992px) {
    .header-date {
        width: inherit;
        min-width: auto;
    }
}

.no-wrap {
    white-space: nowrap;
}

@media(max-width:992px) {
    .no-wrap {
        white-space: initial;
    }
}

@media(max-width: 767px) {
    table.shows-list {
        font-size: 1.8rem;
    }

    table.shows-list,
    table.shows-list tr,
    table.shows-list td,
    table.shows-list th,
    table.shows-list tbody,
    table.shows-list caption {
        display: block;
        width: 100%;
        text-align: left;
    }

    table.shows-list #rowHeader {
        display: none;
    }

    table.shows-list td,
    table.shows-list th {
        padding: 0;
        border-bottom: none;
        word-wrap: wrap;
    }

    table.shows-list th {
        margin-bottom: 10px;
    }

    table.shows-list .button {
        margin-top: 20px;
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
    }

    table.shows-list tr {
        padding: 20px 10px;
        border: 2px solid $secondary;
        margin-bottom: 15px;
    }

    table.shows-list .details-note {
        display: block;
        word-wrap: break-word;
    }
}

.button {
    color: $gold;
    border: 2px solid $gold;
    cursor: pointer;
    display: inline-block;
    font-weight: 400;
    margin: 0 auto;
    padding: 10px 30px;
    text-align: center;
    vertical-align: middle;
    background: 0, 0;
    font-family: $accentFont;
    transition: background .3s ease;
    text-decoration: none;
    border-radius: 0;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.button:hover {
    background: $red;
}

.button.button-red {
    background-color: var(--red);
    color: var(--white);
    border: 1px solid var(--red);
}

.button.button-red:hover {
    color: $secondary;
}

.button.right {
    margin-right: 0;
    margin-left: auto;
    display: block;
}

.venue-link {
    display: inline-block;
    margin-bottom: 7px;
}

.jumbotron {
    color: $gold;
    padding: 0 20px 0 0;
    margin-top: 20px;
    background: rgba(0, 0, 0, .3);
    background-size: contain;
    text-align: right;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @include breakpoint(xs) {
        flex-direction: column;
        text-align: center;
        justify-content: center;
        padding: 0;
    }

    .copy {
        align-self: center;
        padding-left: 20px;

        h2 {
            letter-spacing: 2px;
        }
    }



    .button {
        margin: 10px 0;
    }
}

.secondary {
    text-align: center;
    padding-top: 10px;
}