/*
=============================================================================
                   Normalize, border box and clearfix
=============================================================================
*/
article, aside, details, figcaption, figure, footer, header, hgroup, nav, section, summary {
  display: block; }

audio, canvas, video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden] {
  display: none; }

html {
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

a:focus {
  outline: thin dotted; }

a:active, a:hover {
  outline: 0; }

h1 {
  font-size: 2em; }

abbr[title] {
  border-bottom: 1px dotted; }

b, strong {
  font-weight: 700; }

dfn {
  font-style: italic; }

mark {
  background: #ff0;
  color: #000; }

code, kbd, pre, samp {
  font-family: monospace, serif;
  font-size: 1em; }

pre {
  white-space: pre-wrap;
  word-wrap: break-word; }

q {
  quotes: \201C \201D \2018 \2019; }

small {
  font-size: 80%; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -.5em; }

sub {
  bottom: -.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: .35em .625em .75em; }

button, input, select, textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0; }

button, input {
  line-height: normal; }

button, html input[type=button],
input[type=reset], input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled], input[disabled] {
  cursor: default; }

input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
  padding: 0; }

input[type=search] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }

input[type=search]::-webkit-search-cancel-button, input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none; }

textarea {
  overflow: auto;
  vertical-align: top; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

body, figure {
  margin: 0; }

legend, button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.clearfix:after {
  content: " ";
  visibility: hidden;
  display: block;
  height: 0;
  clear: both; }

/*
====================================
SASS Variables
====================================
*/
/* Fonts */
/* Colours */
/* Margin & Padding */
/*********************
BREAKPOINTS
*********************/
/*
====================================
Custom Font Defaults
====================================
*/
@font-face {
  font-family: 'Helvetica';
  src: url("/ElvisCostelloRebuild/fonts/Helvetica-Bold.woff") format("woff"), url("/ElvisCostelloRebuild/fonts/Helvetica-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'Helvetica';
  src: url("/ElvisCostelloRebuild/fonts/HelveticaLight.woff") format("woff"), url("/ElvisCostelloRebuild/fonts/HelveticaLight.woff2") format("woff2");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: helveticaultra_compressed;
  src: url("/ElvisCostelloRebuild/fonts/Helvetica-UltraCompressed.woff") format("woff"), url("/ElvisCostelloRebuild/fonts/Helvetica-UltraCompressed.woff2") format("woff2");
  font-weight: normal;
  font-style: normal; }

/*
====================================
Typography Defaults
====================================
*/
body {
  font-size: 10px; }

/* paragraphs */
p {
  line-height: 1.4;
  font-size: 1.9rem;
  color: #f9da92; }
  @media (max-width: 767px) {
    p {
      line-height: 1.5; } }

/*blockquotes*/
blockquote {
  margin: 0;
  padding: 20px; }
  blockquote p {
    margin-top: 0; }

/*tables*/
table td {
  padding: 10px; }

table tr td:first {
  padding-right: 0; }

/*def lists*/
dt {
  font-weight: 600;
  padding-bottom: 5px; }

dd {
  padding: 10px;
  margin-left: 10px; }

/*lists*/
ul {
  list-style: none;
  padding-left: 0; }

ol,
dl {
  line-height: 1.8;
  font-size: 1.8rem;
  color: #fad269; }

ul li,
ol li {
  padding: 3px; }

/*PRE*/
pre {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc; }

/* span */
/*figure */
figure {
  margin: 0; }

/* HEADERS */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400; }

h1 {
  font-size: 3.2rem; }

h2 {
  font-size: 2.4rem; }

h3 {
  font-size: 1.9rem; }

h4 {
  font-size: 1.6rem; }

h5 {
  font-size: 1.3rem; }

h6 {
  font-size: 1rem; }

/*
====================================
Global Classes
====================================
*/
html {
  font-size: 62.5%; }

body {
  font-size: 18px;
  font-size: 1.8rem;
  padding: 0;
  background: url(../img/dark-leather.png);
  background-color: #0E212F;
  color: #f9da92;
  font-family: Georgia, Times, Times New Roman, serif; }
  @media (max-width: 992px) {
    body {
      padding-top: 147px; } }

img,
iframe,
svg {
  max-width: 100%;
  flex-shrink: 0; }

img {
  height: auto !important; }

h1 {
  font-size: 36px;
  font-size: 3.6rem; }

h2 {
  font-size: 30px;
  font-size: 3rem; }

h3 {
  font-size: 26px;
  font-size: 2.6rem; }

h4 {
  font-size: 18px;
  font-size: 1.8rem;
  margin-bottom: 20px; }

h5 {
  font-size: 16px;
  font-size: 1.6rem; }

h6 {
  font-size: 14px;
  font-size: 1.4rem; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  font-family: sunflower, sans-serif; }

.contentTitle {
  font-size: 5.2rem;
  text-align: left;
  margin-top: 0;
  margin-bottom: 50px;
  padding-top: 90px;
  font-weight: 400;
  text-transform: uppercase; }
  @media (max-width: 992px) {
    .contentTitle {
      font-size: 3.4rem;
      text-align: center;
      padding-top: 50px; } }

nav ul {
  margin: 0;
  padding: 0;
  list-style: none; }

nav a {
  text-decoration: none; }

a {
  color: #f9da92;
  text-decoration: underline;
  text-decoration-color: transparent;
  transition: text-decoration-color 0.3s ease; }
  a:hover {
    text-decoration-color: inherit; }
  a:focus {
    outline-offset: 5px;
    outline: -webkit-focus-ring-color auto 1px; }

img {
  max-width: 100%;
  height: auto;
  -ms-flex-negative: 0;
  flex-shrink: 0; }

input[type="text"],
input[type="password"],
input[type="date"],
input[type="datetime"],
input[type="email"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
input[type="submit"],
textarea,
select {
  border-color: #fff; }

.inputs input {
  display: inline-block;
  padding: 6px;
  width: 240px;
  margin: 0 6px 10px 0;
  border-radius: 0px;
  vertical-align: middle;
  background: #ffffff;
  border: 2px solid #666;
  transition: border linear .2s, box-shadow linear .2s; }

.inputs select {
  display: inline-block;
  padding: 6px;
  width: 240px;
  margin: 0 6px 10px 0;
  border-radius: 0px;
  vertical-align: middle;
  background: #ffffff;
  border: 2px solid #666;
  -webkit-transition: border linear .2s, box-shadow linear .2s;
  -moz-transition: border linear .2s, box-shadow linear .2s;
  -o-transition: border linear .2s, box-shadow linear .2s;
  transition: border linear .2s, box-shadow linear .2s; }

select {
  display: inline-block;
  padding: 6px;
  width: 240px;
  margin: 0 6px 10px 0;
  border-radius: 0px;
  vertical-align: middle;
  background: #ffffff;
  border: 2px solid #666;
  -webkit-transition: border linear .2s, box-shadow linear .2s;
  -moz-transition: border linear .2s, box-shadow linear .2s;
  -o-transition: border linear .2s, box-shadow linear .2s;
  transition: border linear .2s, box-shadow linear .2s; }

.container {
  width: 1200px;
  max-width: 100%;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px; }

#contentHolder {
  min-height: calc(100vh - 160px);
  padding: 0 0 40px 0; }

.text-center {
  text-align: center; }

.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important; }

.main {
  min-height: calc(100vh - 343px); }

.clearfix:after {
  content: "";
  display: table;
  clear: both;
  float: none; }

.hidden {
  display: none; }

.full {
  width: 100%; }

.columns {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap; }
  @media (max-width: 992px) {
    .columns {
      display: block; } }

.hide {
  display: none; }

.menu-sub-pages .menu {
  display: flex; }
  @media (max-width: 992px) {
    .menu-sub-pages .menu {
      flex-direction: column; } }
  .menu-sub-pages .menu li {
    margin-right: 48px; }
  .menu-sub-pages .menu .current_page_parent a {
    text-decoration: underline; }

/* 
_____________________________________________________________
MODAL
_____________________________________________________________

*/
.modal-open .dropdown-menu {
  z-index: 2050; }

.modal-open .dropdown.open {
  *z-index: 2050; }

.modal-open .popover {
  z-index: 2060; }

.modal-open .tooltip {
  z-index: 2070; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000000; }

.modal-backdrop.fade {
  opacity: 0; }

.modal-backdrop,
.modal-backdrop.fade.in {
  opacity: 0.8;
  filter: alpha(opacity=80); }

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1050;
  overflow: auto;
  width: 560px;
  max-width: 100%;
  background-color: #ffffff;
  border: 2px solid #666;
  /* IE6-7 */
  border-radius: 6px;
  -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding-box;
  background-clip: padding-box;
  color: #1c1b17; }

.modal img {
  display: block;
  margin: 0 auto; }

.modal.fade {
  -webkit-transition: opacity .3s linear, top .3s ease-out;
  -o-transition: opacity .3s linear, top .3s ease-out;
  transition: opacity .3s linear, top .3s ease-out;
  top: -25%; }

.modal.fade.in {
  top: 50%; }

.modal-header {
  padding: 9px 15px;
  border-bottom: 1px solid #eee; }

.modal-header .close {
  margin-top: 2px; }

.modal-body {
  max-height: 350px;
  padding: 15px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }

.modal-form {
  margin-bottom: 0; }

.modal-footer {
  padding: 14px 15px 15px;
  margin-bottom: 0;
  text-align: right;
  background-color: #f5f5f5;
  border-top: 1px solid #ddd;
  border-radius: 0 0 6px 6px;
  -webkit-box-shadow: inset 0 1px 0 #ffffff;
  box-shadow: inset 0 1px 0 #ffffff;
  *zoom: 1; }

.modal-footer:before,
.modal-footer:after {
  display: table;
  content: ""; }

.modal-footer:after {
  clear: both; }

.modal-footer .btn + .btn {
  margin-left: 5px;
  margin-bottom: 0; }

.modal-footer .btn-group .btn + .btn {
  margin-left: -1px; }

.close {
  color: #000000;
  float: right;
  font-size: 20px;
  font-size: 2rem;
  font-weight: bold;
  line-height: 18px;
  opacity: 0.2;
  text-shadow: 0 1px 0 #FFFFFF; }

a.closeBut {
  border-radius: 4px;
  background: #3f322a;
  color: #fff;
  height: 20px;
  line-height: 20px;
  width: auto;
  border: none;
  cursor: pointer;
  display: block;
  font-weight: bold;
  float: right;
  text-align: center;
  width: 50px;
  padding: 6px 10px;
  background: #4c4c4c; }

a.closeBut:hover {
  background: #131313; }

.diamond {
  background: transparent;
  border: 3px solid #f9da92;
  border-radius: 8px;
  height: 40px;
  width: 40px;
  text-align: center;
  transform: rotate(45deg); }
  .diamond a {
    color: #f9da92;
    display: table-cell;
    height: 40px;
    width: 40px;
    transform: rotate(-45deg);
    vertical-align: middle; }

.menu-sub-pages .menu {
  display: flex; }
  @media (max-width: 992px) {
    .menu-sub-pages .menu {
      flex-direction: column; } }
  .menu-sub-pages .menu li {
    margin-right: 48px; }
  .menu-sub-pages .menu .current_page_item a {
    text-decoration: underline; }

.button {
  color: #fff;
  display: inline-block;
  font-weight: 400;
  margin: 0 auto;
  padding: 10px 30px;
  text-align: center;
  vertical-align: middle;
  background-color: #1c1b17;
  transition: all .25s;
  text-decoration: none;
  border-radius: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold; }
  .button:hover {
    background: #3d5270;
    opacity: 1; }
  .button .right {
    margin-right: 0;
    margin-left: auto;
    display: block; }

header.site-header {
  padding: 0 15px;
  position: relative;
  z-index: 4;
  background: url(../img/header-background.jpg);
  background-position: center;
  background-repeat: no-repeat;
  height: 147px;
  filter: drop-shadow(8px 8px 8px #000); }
  @media (max-width: 992px) {
    header.site-header {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 40;
      width: 100vw;
      box-shadow: 0 2px 3px -3px rgba(0, 0, 0, 0.3); } }
  header.site-header .container {
    height: 100%; }
  header.site-header ul {
    padding-left: 0;
    margin: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%; }
    header.site-header ul li {
      padding: 0 5px;
      display: block; }
  header.site-header .header-primary-nav {
    padding-left: 0;
    margin: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    max-width: 430px;
    flex-wrap: wrap; }
    header.site-header .header-primary-nav li {
      padding: 4px 5px;
      display: block; }
    header.site-header .header-primary-nav .current_page_item a,
    header.site-header .header-primary-nav .current-page-ancestor a {
      text-decoration: underline; }
    @media (max-width: 1400px) {
      header.site-header .header-primary-nav {
        max-width: 350px; } }
    @media (max-width: 992px) {
      header.site-header .header-primary-nav {
        max-width: 100%; } }
  header.site-header .header-row-1 {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 100%; }
    @media (max-width: 992px) {
      header.site-header .header-row-1 {
        flex-direction: column; } }
  header.site-header .header-community {
    width: 30%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%; }
    @media (max-width: 992px) {
      header.site-header .header-community {
        width: 100%;
        height: initial; } }
    @media (max-width: 992px) {
      header.site-header .header-community .leftSocial {
        display: none; } }
    header.site-header .header-community .diamond {
      background: 0 0;
      border: 2px solid #f9da92;
      border-radius: 4px;
      height: 32px;
      width: 32px;
      transform: rotate(45deg);
      margin-left: 20px;
      padding: 0 5px; }
      @media (max-width: 1400px) {
        header.site-header .header-community .diamond {
          margin-left: 10px; } }
      header.site-header .header-community .diamond a {
        color: #f9da92;
        display: table-cell;
        height: 40px;
        width: 40px;
        transform: rotate(-45deg);
        vertical-align: middle;
        display: block;
        margin-left: -3px;
        margin-top: 3px; }
      header.site-header .header-community .diamond svg {
        width: 15px;
        height: 15px;
        fill: #f9da92; }
        header.site-header .header-community .diamond svg:hover {
          fill: #a91308; }
    header.site-header .header-community ul {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: flex-start;
      line-height: 1; }
      @media (max-width: 992px) {
        header.site-header .header-community ul {
          justify-content: space-between; } }
      header.site-header .header-community ul li {
        margin-left: 8px; }
        header.site-header .header-community ul li:first-child {
          margin-left: 0; }
        header.site-header .header-community ul li a {
          color: #f9da92;
          font-size: 2.0rem; }
    header.site-header .header-community .has-icon {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center; }
      header.site-header .header-community .has-icon:hover .svg-icon, header.site-header .header-community .has-icon:focus .svg-icon {
        fill: #f9da92; }
    header.site-header .header-community .svg-icon {
      width: 20px;
      height: 15px;
      fill: #f9da92; }
  header.site-header .header-main-navigation {
    display: flex;
    justify-content: center; }
    @media (max-width: 992px) {
      header.site-header .header-main-navigation {
        flex-wrap: wrap; } }
  header.site-header .header-main-nav {
    width: 30%;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column; }
    @media (max-width: 992px) {
      header.site-header .header-main-nav {
        width: 100%; } }
    header.site-header .header-main-nav .menu {
      justify-content: flex-end; }
      @media (max-width: 992px) {
        header.site-header .header-main-nav .menu {
          display: none; } }
      header.site-header .header-main-nav .menu li {
        margin-right: 16px; }
    header.site-header .header-main-nav svg {
      width: 18px;
      height: 18px;
      fill: #f9da92; }
  header.site-header .header-row-2 {
    margin-bottom: 15px; }
    @media (max-width: 992px) {
      header.site-header .header-row-2 {
        margin-bottom: 8px; } }
  header.site-header .logo {
    width: 40%;
    display: flex;
    justify-content: center;
    text-decoration: none;
    align-items: center; }
    @media (max-width: 992px) {
      header.site-header .logo {
        width: 100%;
        justify-content: flex-start; } }
    header.site-header .logo img {
      position: relative;
      top: 20px;
      right: 20px; }
      @media (max-width: 992px) {
        header.site-header .logo img {
          display: none; } }
    header.site-header .logo h1 {
      font-family: sunflower, sans-serif;
      font-size: 6.0rem;
      font-weight: normal;
      text-shadow: 6px 6px #000;
      letter-spacing: 1px; }
      @media (max-width: 1280px) {
        header.site-header .logo h1 {
          font-size: 3.5rem; } }
      @media (max-width: 992px) {
        header.site-header .logo h1 {
          font-size: 3.2rem;
          margin: 0;
          padding: 0; } }
  @media (max-width: 992px) {
    header.site-header #membershipPlaceHolder {
      margin-bottom: 2px; } }
  header.site-header #membershipPlaceHolder svg {
    margin-right: 3px; }
  header.site-header .header-main-navigation a {
    margin: 10px;
    font-size: 2.4rem;
    display: block;
    color: #f9da92;
    text-decoration: none; }
    @media (max-width: 1400px) {
      header.site-header .header-main-navigation a {
        font-size: 2.0rem; } }
    header.site-header .header-main-navigation a:hover {
      text-decoration: underline; }
    header.site-header .header-main-navigation a:focus {
      outline-offset: 5px; }
  header.site-header .header-main-navigation .current-menu-item a,
  header.site-header .header-main-navigation .current-page-ancestor a {
    text-decoration-color: inherit;
    text-decoration-thickness: 2px; }
  @media (max-width: 992px) {
    header.site-header .header-mobile-wrapper {
      height: calc(100vh - 119px);
      overflow-y: scroll;
      position: fixed;
      left: 0;
      right: 0;
      display: none;
      padding: 60px 0;
      background: #3c130c;
      margin-top: 18px; }
    header.site-header .header-mobile-menu-container {
      padding-top: 0px;
      padding-bottom: 0px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; } }
  @media (max-width: 767px) {
    header.site-header .header-mobile-wrapper {
      height: calc(100vh - 85px); } }
  header.site-header .hamburger {
    position: absolute;
    top: 57%;
    transform: translateY(-50%);
    right: 30px;
    z-index: 15;
    display: none;
    padding: 5px 2px 0 2px;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible; }
    header.site-header .hamburger:hover {
      color: #f9da92; }
    header.site-header .hamburger.is-active:hover {
      color: #f9da92; }
    header.site-header .hamburger.is-active .hamburger-inner,
    header.site-header .hamburger.is-active .hamburger-inner::before,
    header.site-header .hamburger.is-active .hamburger-inner::after {
      background-color: #f9da92; }
    @media (max-width: 992px) {
      header.site-header .hamburger {
        display: inline-block; } }
  header.site-header .hamburger-box {
    width: 40px;
    height: 24px;
    display: inline-block;
    position: relative; }
  header.site-header .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px; }
  header.site-header .hamburger-inner,
  header.site-header .hamburger-inner::before,
  header.site-header .hamburger-inner::after {
    width: 40px;
    height: 4px;
    background-color: #f9da92;
    border-radius: 0px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  header.site-header .hamburger:hover .hamburger-inner,
  header.site-header .hamburger:hover .hamburger-inner::before,
  header.site-header .hamburger:hover .hamburger-inner::after {
    background-color: #f9da92; }
  header.site-header .hamburger-inner::before,
  header.site-header .hamburger-inner::after {
    content: "";
    display: block; }
  header.site-header .hamburger-inner::before {
    top: -10px; }
  header.site-header .hamburger-inner::after {
    bottom: -10px; }
  header.site-header .hamburger-spring .hamburger-inner {
    top: 2px;
    transition: background-color 0.3s ease; }
  header.site-header .hamburger-spring .hamburger-inner::before {
    top: 10px;
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0.3s ease; }
  header.site-header .hamburger-spring .hamburger-inner::after {
    top: 20px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0.3s ease; }
  header.site-header .hamburger-spring.is-active .hamburger-inner {
    transition-delay: 0.22s;
    background-color: transparent !important; }
  header.site-header .hamburger-spring.is-active .hamburger-inner::before {
    top: 0;
    transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 10px, 0) rotate(45deg); }
  header.site-header .hamburger-spring.is-active .hamburger-inner::after {
    top: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 10px, 0) rotate(-45deg); }
  header.site-header .header-main-navigation .mobileSocial {
    display: none; }
  @media (max-width: 992px) {
    header.site-header .header-main-navigation ul {
      flex-direction: column; }
      header.site-header .header-main-navigation ul li {
        margin: 8px 0; }
      header.site-header .header-main-navigation ul a {
        font-size: 2.0rem; } }
  header.site-header .header-main-navigation .menu-item-has-children {
    position: relative;
    padding-right: 20px;
    background-image: url(/wp-content/themes/markknopfler/images/caret-down-solid.svg);
    background-size: 13px 18px;
    background-position: 90% 0px;
    background-repeat: no-repeat; }
    @media (max-width: 992px) {
      header.site-header .header-main-navigation .menu-item-has-children {
        background-image: none; } }
  header.site-header .header-main-navigation .menu-item-has-children > a {
    cursor: pointer; }
    @media (max-width: 992px) {
      header.site-header .header-main-navigation .menu-item-has-children > a {
        display: none; } }
  header.site-header .header-main-navigation .sub-menu {
    display: none;
    position: absolute;
    left: 0px;
    top: 0;
    padding-left: 0;
    padding-top: 40px;
    margin-bottom: 0;
    cursor: pointer;
    width: inherit;
    z-index: 2; }
    header.site-header .header-main-navigation .sub-menu li {
      padding: 8px;
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      border-left: 1px solid rgba(0, 0, 0, 0.1);
      display: block;
      text-align: left;
      background: #fff; }
      header.site-header .header-main-navigation .sub-menu li:last-child {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
      header.site-header .header-main-navigation .sub-menu li:first-child, header.site-header .header-main-navigation .sub-menu li:last-child {
        padding: 8px; }
        @media (max-width: 992px) {
          header.site-header .header-main-navigation .sub-menu li:first-child, header.site-header .header-main-navigation .sub-menu li:last-child {
            padding: 0 10px;
            border: none; } }
      @media (max-width: 992px) {
        header.site-header .header-main-navigation .sub-menu li {
          padding: 0 10px;
          text-align: center;
          border: none; } }
    header.site-header .header-main-navigation .sub-menu a {
      display: block; }
      @media (max-width: 992px) {
        header.site-header .header-main-navigation .sub-menu a {
          margin: 0 0 20px; } }
      header.site-header .header-main-navigation .sub-menu a:first-child, header.site-header .header-main-navigation .sub-menu a.last-child {
        margin: 0 5px; }
        @media (max-width: 992px) {
          header.site-header .header-main-navigation .sub-menu a:first-child, header.site-header .header-main-navigation .sub-menu a.last-child {
            margin: 0 0 20px; } }
    @media (max-width: 992px) {
      header.site-header .header-main-navigation .sub-menu {
        display: block;
        position: static;
        padding-top: 0px;
        margin-top: 0;
        margin-bottom: 0px; } }
  header.site-header .menu-item-has-children:focus-within .sub-menu {
    display: block; }
  header.site-header .menu-item-has-children:hover .sub-menu {
    display: block; }

.body-with-open-nav header.site-header .header-mobile-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  @media (max-width: 767px) {
    .body-with-open-nav header.site-header .header-mobile-wrapper {
      justify-content: flex-start; } }

.skip-links-container {
  position: absolute;
  top: 15px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10; }
  @media (max-width: 767px) {
    .skip-links-container {
      top: 85px;
      left: 15px;
      transform: none; } }

.skip-links {
  display: inline-block;
  width: 1px;
  height: 1px;
  overflow: hidden;
  font-size: 1.6rem; }
  .skip-links:focus {
    width: auto;
    height: auto;
    padding: 5px;
    color: #fff;
    background: #000; }

.description {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  width: 100%;
  padding: 0 0 60px; }

.description-left {
  width: 40%;
  padding-right: 60px; }

.description-right {
  width: 60%; }

.description h3 {
  margin-top: 0; }

.intro-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap; }

@media (max-width: 767px) {
  .description {
    display: block; }
  .description-left {
    width: 100%;
    padding-right: 0; }
  .description-right {
    width: 100%; }
  .description h3 {
    margin-top: 30px; }
  .intro-top {
    display: block; } }

.tour-login {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap; }

@media (max-width: 767px) {
  .tour-login {
    display: block; } }

.tour-login p {
  width: 55%;
  padding-right: 15px; }

@media (max-width: 767px) {
  .tour-login p {
    display: block;
    width: 100%;
    padding-right: 0; } }

.tour-login .memberButtons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  margin-top: 18px;
  width: 37%; }

@media (max-width: 767px) {
  .tour-login .memberButtons {
    display: block;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 40px; } }

.tour-login .memberButtons a {
  padding: 10px 25px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: 1px solid #1c1b17;
  margin-right: 20px;
  text-decoration: none;
  font-size: 2rem; }

.tour-login .memberButtons a:hover {
  color: #1c1b17;
  background: #1c1b17; }

.tour-login .memberButtons a:nth-child(2) {
  margin-right: 0; }

@media (max-width: 767px) {
  .tour-login .memberButtons a {
    margin-right: 10px; } }

table.shows-list {
  border-collapse: collapse;
  width: 100%;
  margin-top: 70px; }

@media (max-width: 992px) {
  table.shows-list {
    font-size: 1.6rem; } }

table.shows-list td {
  border-bottom: 1px solid #1c1b17; }

.centered {
  text-align: center; }

table.shows-list th {
  text-align: left; }

table.shows-list td,
table.shows-list th {
  vertical-align: middle;
  border-bottom: 1px solid #f9da92;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 1px;
  padding: 20px 20px 20px 0; }

table.shows-list td:last-child {
  padding-right: 0; }

table.shows-list .details-note {
  padding-top: 10px;
  display: inline-block; }

table.shows-list a {
  font-weight: 400;
  width: 100%; }

table.shows-list .button {
  line-height: 1.5;
  font-size: 1.5rem;
  padding: 10px 15px; }

.header-date {
  width: 28%;
  min-width: 257px; }

@media (max-width: 992px) {
  .header-date {
    width: inherit;
    min-width: auto; } }

.no-wrap {
  white-space: nowrap; }

@media (max-width: 992px) {
  .no-wrap {
    white-space: initial; } }

@media (max-width: 767px) {
  table.shows-list {
    font-size: 1.8rem; }
  table.shows-list,
  table.shows-list tr,
  table.shows-list td,
  table.shows-list th,
  table.shows-list tbody,
  table.shows-list caption {
    display: block;
    width: 100%;
    text-align: left; }
  table.shows-list #rowHeader {
    display: none; }
  table.shows-list td,
  table.shows-list th {
    padding: 0;
    border-bottom: none;
    word-wrap: wrap; }
  table.shows-list th {
    margin-bottom: 10px; }
  table.shows-list .button {
    margin-top: 20px;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px; }
  table.shows-list tr {
    padding: 20px 10px;
    border: 2px solid #1c1b17;
    margin-bottom: 15px; }
  table.shows-list .details-note {
    display: block;
    word-wrap: break-word; } }

.button {
  color: #f9da92;
  border: 2px solid #f9da92;
  cursor: pointer;
  display: inline-block;
  font-weight: 400;
  margin: 0 auto;
  padding: 10px 30px;
  text-align: center;
  vertical-align: middle;
  background: 0, 0;
  font-family: sunflower, sans-serif;
  transition: background .3s ease;
  text-decoration: none;
  border-radius: 0;
  text-transform: uppercase;
  letter-spacing: 1px; }

.button:hover {
  background: #a91308; }

.button.button-red {
  background-color: var(--red);
  color: var(--white);
  border: 1px solid var(--red); }

.button.button-red:hover {
  color: #1c1b17; }

.button.right {
  margin-right: 0;
  margin-left: auto;
  display: block; }

.venue-link {
  display: inline-block;
  margin-bottom: 7px; }

.jumbotron {
  color: #f9da92;
  padding: 0 20px 0 0;
  margin-top: 20px;
  background: rgba(0, 0, 0, 0.3);
  background-size: contain;
  text-align: right;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; }
  @media (max-width: 767px) {
    .jumbotron {
      flex-direction: column;
      text-align: center;
      justify-content: center;
      padding: 0; } }
  .jumbotron .copy {
    align-self: center;
    padding-left: 20px; }
    .jumbotron .copy h2 {
      letter-spacing: 2px; }
  .jumbotron .button {
    margin: 10px 0; }

.secondary {
  text-align: center;
  padding-top: 10px; }

#helpAnswers ul {
  list-style: none;
  padding-left: 0; }

#helpAnswers li {
  margin-top: 70px; }

#helpAnswers h3 {
  margin-bottom: 0px; }

#contactForm {
  max-width: 600px;
  padding: 20px;
  margin: 40px auto 40px 0;
  border: 2px solid; }

#contactForm textarea {
  width: 100%; }

.signInForPresale {
  text-align: center;
  background-color: var(--black);
  padding: 40px 0;
  border: 10px solid #1f3775; }

.signInForPresale .control-group {
  width: 20%; }

@media (max-width: 992px) {
  .signInForPresale .control-group {
    width: 100%; } }

.signInForPresale .form-row {
  justify-content: center; }

.signedInText {
  font-size: 1.6rem; }

@media (max-width: 992px) {
  .signInForPresale {
    margin-top: 30px;
    padding: 20px; } }

.tourBanner {
  width: 100%; }

.tourBanner img {
  width: 100%; }

/*
====================================
Footer
====================================
*/
footer.site-footer {
  background: url(../img/footer-background.jpg);
  background-position: center;
  background-repeat: no-repeat;
  height: 92px;
  color: #f9da92;
  padding-top: 14px;
  filter: drop-shadow(-4px -4px 8px #000); }
  @media (max-width: 992px) {
    footer.site-footer {
      background: #31100c;
      height: 100%; } }
  footer.site-footer a {
    color: #f9da92; }
  footer.site-footer .container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly; }
    @media (max-width: 992px) {
      footer.site-footer .container {
        flex-direction: column; } }

.footer-social ul {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  line-height: 1;
  margin: 10px 0; }
  .footer-social ul li {
    padding: 0 5px;
    display: block; }
    @media (max-width: 767px) {
      .footer-social ul li a {
        margin: 0 5px; } }
    .footer-social ul li a:hover svg, .footer-social ul li a:focus svg {
      fill: #f9da92; }
    .footer-social ul li svg {
      width: 15px;
      height: 15px;
      fill: #f9da92; }
  @media (max-width: 767px) {
    .footer-social ul {
      justify-content: center; } }
  .footer-social ul .diamond {
    background: transparent;
    border: 2px solid #f9da92;
    border-radius: 8px;
    height: 32px;
    width: 32px;
    transform: rotate(45deg);
    margin-left: 12px; }
    .footer-social ul .diamond a {
      color: #f9da92;
      display: table-cell;
      height: 40px;
      width: 40px;
      transform: rotate(-45deg);
      vertical-align: middle;
      display: block;
      margin-left: -3px;
      margin-top: 3px; }

p.footer-copyright {
  font-size: 1.2rem;
  margin-bottom: 0; }
  @media (max-width: 767px) {
    p.footer-copyright {
      text-align: center; } }

.footer-left {
  width: 20%; }
  @media (max-width: 992px) {
    .footer-left {
      width: 100%; } }

.footer-middle {
  width: 60%;
  text-align: center; }
  @media (max-width: 992px) {
    .footer-middle {
      width: 100%; } }
  .footer-middle ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    line-height: 1;
    font-size: 1.4rem;
    margin: 10px 0 20px; }
    @media (max-width: 767px) {
      .footer-middle ul {
        display: block;
        text-align: center;
        font-size: 1.4rem;
        margin-bottom: 0; } }
    .footer-middle ul li {
      padding: 0 9px;
      border-right: 1px solid; }
      .footer-middle ul li:last-child {
        border-right: none;
        padding-right: 0; }
      @media (max-width: 767px) {
        .footer-middle ul li {
          border-right: none;
          margin-bottom: 12px; }
          .footer-middle ul li:last-child {
            padding-right: 9px; } }

.footer-right {
  align-items: flex-end;
  flex-direction: column;
  display: flex;
  width: 20%; }
  @media (max-width: 992px) {
    .footer-right {
      width: 100%;
      align-items: center; } }
  .footer-right .footer-occ-logo {
    width: 157px; }
  .footer-right .do-not-sell-link {
    font-size: 1.0rem; }

.event-detail {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  background: #5c6f8c;
  padding: 30px 0;
  border: 2px solid #C7B686; }
  .event-detail li {
    margin-bottom: 5px; }
  .event-detail > div {
    width: 50%;
    padding: 0 0 0 60px; }
    .event-detail > div:nth-child(2) {
      border-left: 2px solid #C7B686; }

span#ticketPrice {
  font-weight: 400; }

p.price {
  font-size: 2.2rem;
  text-transform: uppercase;
  letter-spacing: 1px; }

.redemption-code-section {
  margin-bottom: 30px; }

p.total {
  font-size: 2.6rem;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: 40px; }

#countdown,
#errorContent ul {
  text-align: center;
  margin: 0 0 5px 0;
  margin: 20px 0;
  padding: 12px 35px 12px 14px;
  border: 0;
  line-height: 1.5;
  background-color: #1f3775;
  color: #fff; }

select {
  cursor: pointer; }

@media (max-width: 767px) {
  .event-detail {
    display: block;
    padding: 15px; }
  .event-detail > div {
    width: 100%;
    padding: 0px; }
  .event-detail > div:nth-child(2) {
    border-top: 1px solid #C7B686;
    border-left: 0;
    padding-left: 0px; }
  .event-detail select {
    width: 100%; } }

.ticket-info-help {
  margin-top: 40px; }

#ticketDetails select,
#ticketDetails input {
  width: auto;
  max-width: 100%; }

.full-order {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap; }

ul#errorMessages {
  list-style: none; }

/*
LEFT
______________________________________________
*/
.purchase-information {
  width: 33%;
  max-width: 33%;
  border: 2px solid #666;
  padding: 0 20px 20px 20px;
  background: #666;
  color: #fff; }
  .purchase-information h3,
  .purchase-information h4,
  .purchase-information h5 {
    margin-bottom: 5px;
    font-weight: bold; }

.charges-heading {
  margin-top: 30px;
  margin-bottom: 10px; }

.charge h5,
.totPrice h5 {
  display: inline-block;
  margin-top: 10px; }

.totPrice {
  font-weight: bold;
  font-size: 2.6rem;
  margin-top: 20px; }

/*
RIGHT
_________________________________________________
*/
.purchase-entry {
  width: 66%;
  max-width: 66%;
  padding-left: 60px; }

fieldset {
  padding: 20px;
  border: 2px solid #666;
  background: #666; }

.form-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: nowrap; }

.inputs input,
.inputs select {
  width: 100%;
  display: block; }

input[type="checkbox"] {
  margin-right: 5px; }

label.control-label {
  display: block;
  margin-top: 10px;
  margin-bottom: 5px;
  text-transform: uppercase;
  letter-spacing: 1px; }

.help-inline {
  color: #1f3775;
  display: inline; }

.cc-image {
  position: relative;
  top: 4px; }

.purchase-entry .control-group:last-child {
  margin-bottom: 0px; }

fieldset.form-actions {
  border: none;
  background: none; }

.billingAddressPanel {
  margin-top: 2px; }

.control-group {
  width: 48%; }

.control-group.full {
  width: 100%; }

.billingAddressPanel .intro-top {
  align-items: center; }

.paymentForm .form-row {
  display: block;
  margin-bottom: 20px; }

.paymentForm .control-group {
  margin-left: auto;
  margin-right: auto; }

.security-info {
  width: 48%;
  margin-left: auto;
  margin-right: auto; }

@media (max-width: 767px) {
  .security-info {
    width: 100%; } }

.security-info h5 {
  margin-bottom: 0; }

.security-info p {
  font-size: 1.4rem; }

.alert {
  background-color: #e1b441;
  font-size: 1.8rem;
  padding: 15px;
  color: #1c1b17; }

fieldset {
  margin-bottom: 20px; }

.form-actions {
  text-align: center; }

.form-actions .button {
  margin: 0 10px; }

@media (max-width: 992px) {
  .full-order {
    display: block; }
  .purchase-information {
    width: max-content;
    max-width: 100%;
    padding: 0 20px 20px 20px; }
  .purchase-entry {
    width: 100%;
    max-width: 100%;
    padding-left: 0px; } }

@media (max-width: 767px) {
  .form-row {
    display: block; }
  .control-group {
    width: 100%; }
  .form-actions .button {
    margin: 0 auto 20px; }
  fieldset.form-actions {
    padding-left: 0;
    padding-right: 0; }
  #purchaseView fieldset {
    padding: 20px 10px; }
  .purchase-information {
    padding: 0 10px 20px; } }

#orderCommentsForm fieldset {
  padding: 0;
  border: none;
  background: none; }

#orderCommentsForm fieldset .control-group,
#orderCommentsForm fieldset textarea {
  width: 100%; }

#confirmationLinks {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin-top: 30px; }

#confirmationLinks a {
  margin-bottom: 10px;
  display: inline; }

.members-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start; }
  @media (max-width: 767px) {
    .members-content {
      display: block; } }
  .members-content a {
    box-shadow: none; }
    .members-content a:hover {
      box-shadow: none;
      background-color: transparent; }
  .members-content .single-item {
    width: calc(50% - 30px);
    margin-bottom: 50px; }
    @media (max-width: 767px) {
      .members-content .single-item {
        width: 100%; } }
  .members-content h3 {
    margin-top: 10px; }
  .members-content svg {
    width: 60px;
    height: 60px;
    fill: #fad269; }
  .members-content .single-item a:hover svg,
  .members-content .single-item a:focus svg {
    fill: #fad269; }

.membership-controls {
  display: flex;
  justify-content: center;
  font-size: 1.8rem;
  border: solid 1px #f9da92;
  margin-top: 20px; }
  .membership-controls .member-links {
    display: flex;
    justify-content: center; }
    .membership-controls .member-links a {
      color: #f9da92;
      font-size: 1.9rem; }
      .membership-controls .member-links a:hover, .membership-controls .member-links a:focus {
        color: #f9da92; }
    .membership-controls .member-links li {
      margin: 0 12px; }
  .membership-controls .membership-error {
    background-color: #a91308;
    padding: 0 10px; }
  .membership-controls .form-control {
    width: 500px;
    margin: 12px 0;
    text-align: left; }
    @media (max-width: 992px) {
      .membership-controls .form-control {
        width: 100%; } }
    .membership-controls .form-control input {
      height: 45px;
      width: 100%;
      border: 1px solid #000; }
  .membership-controls .sign-in-out {
    background-color: transparent;
    color: #f9da92;
    cursor: pointer;
    padding: 8px;
    border: solid 1px #f9da92;
    margin-left: 10px; }
    .membership-controls .sign-in-out:focus, .membership-controls .sign-in-out:hover {
      background-color: #a91308; }
  .membership-controls .form-checkbox {
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: center; }
    .membership-controls .form-checkbox p {
      width: initial;
      margin: initial; }
    .membership-controls .form-checkbox input {
      margin: 0 0 0 12px; }
  .membership-controls .button-style-1 {
    background: transparent;
    color: #f9da92;
    justify-content: center;
    display: flex;
    cursor: pointer;
    margin: 10px auto;
    border: solid 1px #f9da92;
    padding: 8px; }
    .membership-controls .button-style-1:focus, .membership-controls .button-style-1:hover {
      background: #a91308; }
  .membership-controls .membership-logged-in {
    width: 100%;
    height: 100px;
    padding: 0 20px;
    display: flex; }
    @media (max-width: 992px) {
      .membership-controls .membership-logged-in {
        flex-direction: column;
        height: initial;
        padding: 20px 0; } }
    @media (max-width: 992px) {
      .membership-controls .membership-logged-in .membership-logged-in-username {
        text-align: center;
        margin: 0 0 20px 0; } }
    .membership-controls .membership-logged-in form {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      width: 100%; }
      @media (max-width: 992px) {
        .membership-controls .membership-logged-in form {
          flex-direction: column; } }

header .membership {
  display: flex;
  justify-content: flex-end;
  height: 50px;
  align-items: center;
  margin-top: 12px; }
  @media (max-width: 992px) {
    header .membership {
      display: none; } }
  header .membership .membership-logged-out .membership-error {
    background-color: #a91308;
    margin-right: 10px;
    padding: 0 8px; }
  header .membership .membership-logged-out form {
    display: flex;
    align-items: center; }
    @media (max-width: 992px) {
      header .membership .membership-logged-out form {
        flex-direction: column; } }
    header .membership .membership-logged-out form .form-control label {
      display: none; }
    header .membership .membership-logged-out form .form-control input {
      height: 35px;
      margin-right: 20px; }
    header .membership .membership-logged-out form .form-checkbox {
      display: none;
      align-items: center; }
      header .membership .membership-logged-out form .form-checkbox input {
        margin: 0 8px; }
  header .membership .membership-logged-in {
    display: flex; }
    header .membership .membership-logged-in form {
      display: flex;
      align-items: center; }
    header .membership .membership-logged-in .logged-in-controls a {
      font-size: 1.4rem;
      display: inline-block;
      margin: 0 12px;
      color: #f9da92; }

.logged-in-controls a {
  margin-right: 10px;
  color: #f9da92; }

@media (max-width: 992px) {
  header.site-header ul li.membership-menu {
    display: none; } }

@media (max-width: 992px) {
  .membership-menu {
    display: none; } }

.membership-menu .membership-logged-in {
  display: flex; }
  .membership-menu .membership-logged-in form {
    display: flex;
    align-items: center; }
  .membership-menu .membership-logged-in .logged-in-controls a {
    font-size: 1.4rem;
    display: inline-block;
    margin: 0 20px 0 0; }
    .membership-menu .membership-logged-in .logged-in-controls a:hover svg, .membership-menu .membership-logged-in .logged-in-controls a:focus svg {
      fill: #a91308; }
    .membership-menu .membership-logged-in .logged-in-controls a svg {
      width: 20px;
      height: 15px;
      fill: #e9b690; }

/*
====================================
Default Styling
====================================
*/
