.event-detail {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    background: $quat;
    padding: 30px 0;
    border: 2px solid $mkyellow;
    li {
        margin-bottom: 5px;
    }
    >div {
        width: 50%;
        padding: 0 0 0 60px;
        &:nth-child(2) {
            border-left: 2px solid $mkyellow;
        }
    }
}

span#ticketPrice {
    font-weight: 400;
}

p.price {
    font-size: 2.2rem;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.redemption-code-section {
    margin-bottom: 30px;
}

p.total {
    font-size: 2.6rem;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-top: 40px;
}

#countdown,
#errorContent ul {
    text-align: center;
    margin: 0 0 5px 0;
    margin: 20px 0;
    padding: 12px 35px 12px 14px;
    border: 0;
    line-height: 1.5;
    background-color: $tertiary;
    color: $white;
}

select {
    cursor: pointer;
}

@media(max-width: 767px) {
    .event-detail {
        display: block;
        padding: 15px;
    }
    .event-detail>div {
        width: 100%;
        padding: 0px;
    }
    .event-detail>div:nth-child(2) {
        border-top: 1px solid $mkyellow;
        border-left: 0;
        padding-left: 0px;
    }
    .event-detail select {
        width: 100%;
    }
}

.ticket-info-help {
    margin-top: 40px;
}

#ticketDetails select,
#ticketDetails input {
    width: auto;
    max-width: 100%;
}