/*
====================================
Footer
====================================
*/

footer.site-footer {
    background: url(../img/footer-background.jpg);
    background-position: center;
    background-repeat: no-repeat;
    height: 92px;
    color: $gold;
    // font-family: $accentFont;
    padding-top: 14px;
    filter: drop-shadow(-4px -4px 8px #000);

    // margin-top: 80px;
    @include breakpoint(sm) {
        background: $brown;
        height: 100%;
    }

    a {
        color: $gold;
    }

    .container {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;

        @include breakpoint(sm) {
            flex-direction: column;
        }
    }
}

.footer-social ul {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    line-height: 1;
    margin: 10px 0;

    li {
        padding: 0 5px;
        display: block;

        a {


            @include breakpoint(xs) {
                margin: 0 5px;
            }

            &:hover,
            &:focus {
                svg {
                    fill: $gold;
                }
            }
        }

        svg {
            width: 15px;
            height: 15px;
            fill: $gold;
        }
    }

    @include breakpoint(xs) {
        justify-content: center;
    }

    .diamond {
        background: transparent;
        border: 2px solid $gold;
        border-radius: 8px;
        height: 32px;
        width: 32px;

        transform: rotate(45deg);

        margin-left: 12px;

        a {
            color: $gold;
            display: table-cell;
            height: 40px;
            width: 40px;
            transform: rotate(-45deg);
            vertical-align: middle;
            display: block;
            margin-left: -3px;
            margin-top: 3px;
        }
    }
}



p.footer-copyright {
    font-size: 1.2rem;

    margin-bottom: 0;


    @include breakpoint(xs) {
        text-align: center;
    }
}

.footer-left {
    width: 20%;

    @include breakpoint(sm) {
        width: 100%;
    }
}

.footer-middle {
    width: 60%;
    text-align: center;

    @include breakpoint(sm) {
        width: 100%;
    }

    ul {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
        line-height: 1;
        font-size: 1.4rem;
        margin: 10px 0 20px;

        @include breakpoint(xs) {
            display: block;
            text-align: center;
            font-size: 1.4rem;
            margin-bottom: 0;
        }

        li {
            padding: 0 9px;
            border-right: 1px solid;

            &:last-child {
                border-right: none;
                padding-right: 0;
            }

            @include breakpoint(xs) {
                border-right: none;
                margin-bottom: 12px;

                &:last-child {
                    padding-right: 9px;
                }
            }
        }
    }
}

.footer-right {
    align-items: flex-end;
    flex-direction: column;
    display: flex;
    width: 20%;

    @include breakpoint(sm) {
        width: 100%;
        align-items: center;
    }

    .footer-occ-logo {
        width: 157px;

    }

    .do-not-sell-link {
        font-size: 1.0rem;
    }
}