.members-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
  
    @include breakpoint(xs) {
      display: block;
    }
  
    a {
      box-shadow: none;
  
      &:hover {
        box-shadow: none;
        background-color: transparent;
      }
    }
  
  
    .single-item {
      width: calc(50% - 30px);
      margin-bottom: 50px;
  
      @include breakpoint(xs) {
        width: 100%;
      }
    }
  
    h3 {
      margin-top: 10px;
    }
  
    svg {
      width: 60px;
      height: 60px;
      fill: $primary;
    }
  
    .single-item a:hover svg,
    .single-item a:focus svg {
      fill: $primary;
    }
  }
  
  .membership-controls {
    display: flex;
    justify-content: center;
    font-size: 1.8rem;
    border: solid 1px $gold;
    margin-top: 20px;
  
    .member-links {
      display: flex;
      justify-content: center;
  
      a {
        color: $gold;
        font-size: 1.9rem;
  
        &:hover,
        &:focus {
          color: $gold;
        }
      }
  
      li {
        margin: 0 12px;
      }
    }
  
    .membership-error {
      background-color: $red;
      padding: 0 10px;
    }
  
    .form-control {
      width: 500px;
      margin: 12px 0;
      text-align: left;
  
      @include breakpoint(sm) {
        width: 100%;
      }
  
      input {
        height: 45px;
        width: 100%;
        border: 1px solid $black;
      }
    }

    .sign-in-out {
      background-color: transparent;
      color: $gold;
      cursor: pointer;
      padding: 8px;
      border: solid 1px $gold;
      margin-left: 10px;

      &:focus,
      &:hover {
        background-color: $red;
      }
    }
  
    .form-checkbox {
      display: flex;
      height: 40px;
      align-items: center;
      justify-content: center;
  
      p {
        width: initial;
        margin: initial;
      }
  
      input {
        margin: 0 0 0 12px;
      }
    }
  
    .button-style-1 {
      background: transparent;
      color: $gold;
      justify-content: center;
      display: flex;
      cursor: pointer;
      margin: 10px auto; 
      border: solid 1px $gold;
      padding: 8px;

      &:focus,
      &:hover {
        background: $red;
      }
    }
  
    .membership-logged-in {
      width: 100%;
      height: 100px;
      padding: 0 20px;
      display: flex;
  
      @include breakpoint(sm) {
        flex-direction: column;
        height: initial;
        padding: 20px 0;
      }
  
      .membership-logged-in-username {
        @include breakpoint(sm) {
          text-align: center;
          margin: 0 0 20px 0;
        }
      }
  
      form {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        width: 100%;
  
        @include breakpoint(sm) {
          flex-direction: column;
        }
      }
    }
  }
  
  header {
    .membership {
      display: flex;
      justify-content: flex-end;
      height: 50px;
      align-items: center;
      margin-top: 12px;
  
      @include breakpoint(sm) {
        // flex-direction: column;
        // height: initial;
        display: none;
      }
  
    
  
      .membership-logged-out {
  
        .membership-error {
          background-color: $red;
          margin-right: 10px;
          padding: 0 8px;
        }
        
  
        form {
          display: flex;
          align-items: center;
  
          @include breakpoint(sm) {
            flex-direction: column;
          }
  
          .form-control {
            label {
              display: none;
            }
  
            input {
              height: 35px;
              margin-right: 20px;
            }
          }
  
          .form-checkbox {
            display: none;
            align-items: center;
  
            input {
              margin: 0 8px;
            }
          }
        }
      }
  
      .membership-logged-in {
        display: flex;
  
        form {
          display: flex;
          align-items: center;
        }
  
        .logged-in-controls {
          a {
            font-size: 1.4rem;
            // font-weight: bold;
            // text-shadow: 6px 6px 6px #000;
            display: inline-block;
            margin: 0 12px;
            color: $gold;
          }
        }
      }
    }
  }
  
  .logged-in-controls {
  
  
    a {
      margin-right: 10px;
      color: $gold;
    }
  }
  
  header.site-header ul li.membership-menu {
    @include breakpoint(sm) {
      display: none;
    }
  }
  
  .membership-menu {
    @include breakpoint(sm) {
      display: none;
    }
  
    
  
    .membership-logged-in {
      display: flex;
  
      form {
        display: flex;
        align-items: center;
      }
  
      .logged-in-controls {
        a {
          font-size: 1.4rem;
          // font-weight: bold;
          // text-shadow: 6px 6px 6px #000;
          display: inline-block;
          margin: 0 20px 0 0;
  
          &:hover,
          &:focus {
            svg {
              fill: $red;
            }
          }
  
          svg {
            width: 20px;
            height: 15px;
            fill: #e9b690;
  
  
          }
        }
      }
    }
  }