/*
====================================
Typography Defaults
====================================
*/

body {
    font-size: 10px;
}




/* paragraphs */

p {
    line-height: 1.4;
    font-size: 1.9rem;
    color: $gold;

    @include breakpoint(xs) {
        line-height: 1.5;
    }
}


/*blockquotes*/

blockquote {
    margin: 0;
    padding: 20px;

    p {
        margin-top: 0;
    }
}


/*tables*/

table {
    td {
        padding: 10px;
    }

    tr td:first {
        padding-right: 0;
    }
}


/*def lists*/

dt {
    font-weight: 600;
    padding-bottom: 5px;
}

dd {
    padding: 10px;
    margin-left: 10px;
}


/*lists*/

ul {
    list-style: none;
    padding-left: 0;
}

ol,
dl {
    line-height: 1.8;
    font-size: 1.8rem;
    color: $primary;
}

ul li,
ol li {
    padding: 3px;
}


/*PRE*/

pre {
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
}


/* span */


/*figure */

figure {
    margin: 0;
}


/* HEADERS */

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 400;
}

h1 {
    font-size: 3.2rem;
}

h2 {
    font-size: 2.4rem;
}

h3 {
    font-size: 1.9rem;
}

h4 {
    font-size: 1.6rem;
}

h5 {
    font-size: 1.3rem;
}

h6 {
    font-size: 1rem;
}