/*
====================================
Global Classes
====================================
*/

html {
    font-size: 62.5%;
}

body {
    font-size: 18px;
    font-size: 1.8rem;
    padding: 0;
    background: url(../img/dark-leather.png);
    background-color: #0E212F;
    color: $gold; 
    font-family: $bodyFont;


    @include breakpoint(sm) {
        // ATTN: THIS NEEDS TO BE EDITED TO NATURAL HEIGHT OF FIXED HEADER (if header is fixed) AT THIS BREAKPOINT
        padding-top: 147px;
    }
}

img,
iframe,
svg {
    max-width: 100%;
    flex-shrink: 0;
    //crucial for internet explorer! 
}

img {
    height: auto !important;
}

h1 {
    font-size: 36px;
    font-size: 3.6rem;
}

h2 {
    font-size: 30px;
    font-size: 3rem;
}

h3 {
    font-size: 26px;
    font-size: 2.6rem;
}

h4 {
    font-size: 18px;
    font-size: 1.8rem;
    margin-bottom: 20px;
}

h5 {
    font-size: 16px;
    font-size: 1.6rem;
}

h6 {
    font-size: 14px;
    font-size: 1.4rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 400;
    font-family: $accentFont;
}

.contentTitle {
    font-size: 5.2rem;
    text-align: left;
    margin-top: 0;
    margin-bottom: 50px;
    padding-top: 90px;
    font-weight: 400;
    text-transform: uppercase;

    @include breakpoint(sm) {
        font-size: 3.4rem;
        text-align: center;
        padding-top: 50px;
    }
}

nav ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

nav a {
    text-decoration: none;
}

a {
    color: $gold;
    text-decoration: underline;
    //make link underline have nice transition
    text-decoration-color: transparent;
    transition: text-decoration-color 0.3s ease;

    &:hover {
        text-decoration-color: inherit;
    }

    &:focus {
        outline-offset: 5px;
        outline: -webkit-focus-ring-color auto 1px;
    }
}



img {
    max-width: 100%;
    height: auto;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

input[type="text"],
input[type="password"],
input[type="date"],
input[type="datetime"],
input[type="email"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
input[type="submit"],
textarea,
select {
    border-color: $white;
}

.inputs {
    input {
        display: inline-block;
        padding: 6px;
        width: 240px;
        margin: 0 6px 10px 0;
        border-radius: 0px;
        vertical-align: middle;
        background: #ffffff;
        border: 2px solid $gray;
        transition: border linear .2s, box-shadow linear .2s;
    }

    select {
        display: inline-block;
        padding: 6px;
        width: 240px;
        margin: 0 6px 10px 0;
        border-radius: 0px;
        vertical-align: middle;
        background: #ffffff;
        border: 2px solid $gray;
        -webkit-transition: border linear .2s, box-shadow linear .2s;
        -moz-transition: border linear .2s, box-shadow linear .2s;
        -o-transition: border linear .2s, box-shadow linear .2s;
        transition: border linear .2s, box-shadow linear .2s;
    }
}

select {
    display: inline-block;
    padding: 6px;
    width: 240px;
    margin: 0 6px 10px 0;
    border-radius: 0px;
    vertical-align: middle;
    background: #ffffff;
    border: 2px solid $gray;
    -webkit-transition: border linear .2s, box-shadow linear .2s;
    -moz-transition: border linear .2s, box-shadow linear .2s;
    -o-transition: border linear .2s, box-shadow linear .2s;
    transition: border linear .2s, box-shadow linear .2s;
}

.container {
    width: 1200px;
    max-width: 100%;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
}

#contentHolder {
    min-height: calc(100vh - 160px);
    padding: 0 0 40px 0;
}

.text-center {
    text-align: center;
}

.screen-reader-text {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    width: 1px;
    word-wrap: normal !important;
}

.main {
    min-height: calc(100vh - 343px);
}

.clearfix:after {
    content: "";
    display: table;
    clear: both;
    float: none;
}

.hidden {
    display: none;
}

.full {
    width: 100%;
}

.columns {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    @include breakpoint(sm) {
        display: block;
    }
}

.hide {
    display: none;
}

.menu-sub-pages {
    .menu {
        display: flex;

        @include breakpoint(sm) {
            flex-direction: column;
        }

        li {
            margin-right: 48px;
        }

        .current_page_parent {
            a {
                text-decoration: underline;
            }
        }
    }
}


/* 
_____________________________________________________________
MODAL
_____________________________________________________________

*/

.modal-open .dropdown-menu {
    z-index: 2050;
}

.modal-open .dropdown.open {
    *z-index: 2050;
}

.modal-open .popover {
    z-index: 2060;
}

.modal-open .tooltip {
    z-index: 2070;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: #000000;
}

.modal-backdrop.fade {
    opacity: 0;
}

.modal-backdrop,
.modal-backdrop.fade.in {
    opacity: 0.8;
    filter: alpha(opacity=80);
}

.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 1050;
    overflow: auto;
    width: 560px;
    max-width: 100%;
    background-color: #ffffff;
    border: 2px solid $gray;
    /* IE6-7 */
    border-radius: 6px;
    -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding-box;
    background-clip: padding-box;
    color: $secondary;
}

.modal img {
    display: block;
    margin: 0 auto;
}

.modal.fade {
    -webkit-transition: opacity .3s linear, top .3s ease-out;
    -o-transition: opacity .3s linear, top .3s ease-out;
    transition: opacity .3s linear, top .3s ease-out;
    top: -25%;
}

.modal.fade.in {
    top: 50%;
}

.modal-header {
    padding: 9px 15px;
    border-bottom: 1px solid #eee;
}

.modal-header .close {
    margin-top: 2px;
}

.modal-body {
    max-height: 350px;
    padding: 15px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

.modal-form {
    margin-bottom: 0;
}

.modal-footer {
    padding: 14px 15px 15px;
    margin-bottom: 0;
    text-align: right;
    background-color: #f5f5f5;
    border-top: 1px solid #ddd;
    border-radius: 0 0 6px 6px;
    -webkit-box-shadow: inset 0 1px 0 #ffffff;
    box-shadow: inset 0 1px 0 #ffffff;
    *zoom: 1;
}

.modal-footer:before,
.modal-footer:after {
    display: table;
    content: "";
}

.modal-footer:after {
    clear: both;
}

.modal-footer .btn+.btn {
    margin-left: 5px;
    margin-bottom: 0;
}

.modal-footer .btn-group .btn+.btn {
    margin-left: -1px;
}

.close {
    color: #000000;
    float: right;
    font-size: 20px;
    font-size: 2rem;
    font-weight: bold;
    line-height: 18px;
    opacity: 0.2;
    text-shadow: 0 1px 0 #FFFFFF;
}

a.closeBut {
    border-radius: 4px;
    background: #3f322a;
    color: #fff;
    height: 20px;
    line-height: 20px;
    width: auto;
    border: none;
    cursor: pointer;
    display: block;
    font-weight: bold;
    float: right;
    text-align: center;
    width: 50px;
    padding: 6px 10px;
    background: #4c4c4c;
}

a.closeBut:hover {
    background: #131313;
}

.diamond {
    background: transparent;
    border: 3px solid $gold;
    border-radius: 8px;
    height: 40px;
    width: 40px;
    text-align: center;
    transform: rotate(45deg);

    a {
        color: $gold;
        display: table-cell;
        height: 40px;
        width: 40px;
        transform: rotate(-45deg);
        vertical-align: middle;
    }
}

.menu-sub-pages {
    .menu {
        display: flex;

        @include breakpoint(sm) {
            flex-direction: column;
        }

        li {
            margin-right: 48px;
        }

        .current_page_item {
            a {
                text-decoration: underline;
            }
        }
    }
}