#helpAnswers ul {
    list-style: none;
    padding-left: 0;
}

#helpAnswers li {
    margin-top: 70px;
}

#helpAnswers h3 {
    margin-bottom: 0px;
}

#contactForm {
    max-width: 600px;
    padding: 20px;
    margin: 40px auto 40px 0;
    border: 2px solid;
}

#contactForm textarea {
    width: 100%;
}

.signInForPresale {
    text-align: center;
    background-color: var(--black);
    padding: 40px 0;
    border: 10px solid $tertiary;
}

.signInForPresale .control-group {
    width: 20%;
}

@media(max-width: 992px) {
    .signInForPresale .control-group {
        width: 100%;
    }
}

.signInForPresale .form-row {
    justify-content: center;
}

.signedInText {
    font-size: 1.6rem;
}

@media(max-width: 992px) {
    .signInForPresale {
        margin-top: 30px;
        padding: 20px;
    }
}

.tourBanner {
    width: 100%;
}

.tourBanner img {
    width: 100%;
}