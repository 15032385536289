.button {
    color: $white;
    display: inline-block;
    font-weight: 400;
    margin: 0 auto;
    padding: 10px 30px;
    text-align: center;
    vertical-align: middle;
    background-color: $secondary;
    transition: all .25s;
    text-decoration: none;
    border-radius: 0;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: bold;
    &:hover {
        background: #3d5270;
        opacity: 1;
    }
    .right {
        margin-right: 0;
        margin-left: auto;
        display: block;
    }
}