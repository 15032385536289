/*
====================================
SASS Variables
====================================
*/

//Column Flex
@mixin column {
    display: flex;
    flex-direction: column;
}


/* Fonts */

$bodyFont: Georgia,
Times,
Times New Roman,
serif;
$accentFont: sunflower,
sans-serif;

/* Colours */


$primary: #fad269;
$primary-light: #f5d379;
$secondary: #1c1b17;
$tertiary: #1f3775;
$quat:#5c6f8c;
$quint:#404554;
$accent:#e1b441;

$mklightblue: #e9f3ff;
$mkmedblue: #4E6A94;
$mkdarkblue: #2B384B;
$mkgray: #777871;
$mkyellow: #C7B686;
$gray: #666;

$black: #000;
$white: #fff;
$grey: #444;
$red: #a91308;
$gold: #f9da92;
$brown: #31100c;

//Provides cutout header
@mixin cutout {
    background-color: #0a4062;
    color: #dbc1aa;
    display: inline-block;
    border: none;
    font-weight: 700;
}

//Inverts colors, useful for headers
@mixin invert {
    background: $secondary;
    color: $primary;
}


/* Margin & Padding */

$margin: 5% 0;
$padding: 5% 10%;

//Displays content in a padded column, useful for home page sections
@mixin subcontent {
    display: flex;
    flex-direction: column;
    padding: 2%;
}


/*********************
BREAKPOINTS
*********************/

@mixin breakpoint($point) {
    @if $point==xl {
        @media (min-width: 1500px) {
            @content ;
        }
    }

    @else if $point==lg {
        @media (max-width: 1400px) {
            @content ;
        }
    }

    @else if $point==premedium {
        @media (max-width: 1280px) {
            @content ;
        }
    }

    @else if $point==md {
        @media (max-width: 1199px) {
            @content ;
        }
    }

    @else if $point==presmall {
        @media (max-width: 1030px) {
            @content ;
        }
    }

    @else if $point==sm {
        @media (max-width: 992px) {
            @content ;
        }
    }

    @else if $point==xs {
        @media (max-width: 767px) {
            @content ;
        }
    }

    @else if $point==xxs {
        @media (max-width: 370px) {
            @content ;
        }
    }
}