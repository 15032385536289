 #orderCommentsForm fieldset {
     padding: 0;
     border: none;
     background: none;
 }

 #orderCommentsForm fieldset .control-group,
 #orderCommentsForm fieldset textarea {
     width: 100%;
 }

 #confirmationLinks {
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     -webkit-box-orient: vertical;
     -webkit-box-direction: normal;
     -ms-flex-direction: column;
     flex-direction: column;
     -webkit-box-pack: start;
     -ms-flex-pack: start;
     justify-content: flex-start;
     margin-top: 30px;
 }

 #confirmationLinks a {
     margin-bottom: 10px;
     display: inline;
 }