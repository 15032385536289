.full-order {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap;
}

ul#errorMessages {
    list-style: none;
}

/*
LEFT
______________________________________________
*/

.purchase-information {
    width: 33%;
    max-width: 33%;
    border: 2px solid $gray;
    padding: 0 20px 20px 20px;
    background: $gray;
    color: $white;

    h3,
    h4,
    h5 {
        margin-bottom: 5px;
        font-weight: bold;
    }
}


.charges-heading {
    margin-top: 30px;
    margin-bottom: 10px;
}



.charge h5,
.totPrice h5 {
    display: inline-block;
    margin-top: 10px;
}

.totPrice {
    font-weight: bold;
    font-size: 2.6rem;
    margin-top: 20px;
}

/*
RIGHT
_________________________________________________
*/

.purchase-entry {
    width: 66%;
    max-width: 66%;
    padding-left: 60px;
}

fieldset {
    padding: 20px;
    border: 2px solid $gray;
    background: $gray;
}

.form-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: nowrap;
}

.inputs input,
.inputs select {
    width: 100%;
    display: block;
}

input[type="checkbox"] {
    margin-right: 5px;
}

label.control-label {
    display: block;
    margin-top: 10px;
    margin-bottom: 5px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.help-inline {
    color: $tertiary;
    display: inline;
}

.cc-image {
    position: relative;
    top: 4px;
}

.purchase-entry .control-group:last-child {
    margin-bottom: 0px;
}

fieldset.form-actions {
    border: none;
    background: none;
}

.billingAddressPanel {
    margin-top: 2px;
}

.control-group {
    width: 48%;
}

.control-group.full {
    width: 100%;
}

.billingAddressPanel .intro-top {
    align-items: center;
}

.paymentForm .form-row {
    display: block;
    margin-bottom: 20px;
}

.paymentForm .control-group {
    margin-left: auto;
    margin-right: auto;
}

.security-info {
    width: 48%;
    margin-left: auto;
    margin-right: auto;
}

@media(max-width: 767px) {
    .security-info {
        width: 100%;
    }
}

.security-info h5 {
    margin-bottom: 0;
}

.security-info p {
    font-size: 1.4rem;
}

.alert {
    background-color: $accent;
    font-size: 1.8rem;
    padding: 15px;
    color: $secondary;
}

fieldset {
    margin-bottom: 20px;
}

.form-actions {
    text-align: center;
}

.form-actions .button {
    margin: 0 10px;
}

@media(max-width: 992px) {
    .full-order {
        display: block;
    }

    .purchase-information {
        width: max-content;
        max-width: 100%;
        padding: 0 20px 20px 20px;
    }

    .purchase-entry {
        width: 100%;
        max-width: 100%;
        padding-left: 0px;
    }
}

@media(max-width: 767px) {
    .form-row {
        display: block;
    }

    .control-group {
        width: 100%;
    }

    .form-actions .button {
        margin: 0 auto 20px;
    }

    fieldset.form-actions {
        padding-left: 0;
        padding-right: 0;
    }

    #purchaseView fieldset {
        padding: 20px 10px;
    }

    .purchase-information {
        padding: 0 10px 20px;
    }
}